import React from "react"
import Slider from "react-slick"
import "../styles/slick.min.css"
import "../styles/slick-theme.min.css"
import { Helmet } from "react-helmet"
import "lazysizes"
class ClientLogos extends React.Component {
  render() {
    var testimonailSettings = {
      dots: false,
      speed: 900,
      autoplay: true,
      slidesToShow: 8,
      slidesToScroll: 3,
      autoplaySpeed: 2000,
      cssEase: "linear",
      arrows: true,
      margin: 10,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 5,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    }
    return (
      <div>
        <Helmet>
          <script
            src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
            integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
            crossorigin="anonymous"
            defer
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
            integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
            crossorigin="anonymous"
            defer
          ></script>
          <script
            src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
            integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
            crossorigin="anonymous"
            defer
          ></script>
        </Helmet>
        <div>
          <Slider {...testimonailSettings}>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/chargebee.png"
                className="lazyload"
                alt="Charge Bee"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/wingify.png"
                className="lazyload"
                alt="wingify"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/branch.png"
                className="lazyload"
                alt="branch"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/cactus.png"
                className="lazyload"
                alt="Cactus"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/gain-credit.png"
                className="lazyload"
                alt="Gain Credit"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/the-better-india.png"
                className="lazyload"
                alt="The Better India"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/infeedo.png"
                className="lazyload"
                alt="Infeedo"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/fabric.png"
                className="lazyload"
                alt="Fabric"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/acko.png"
                className="lazyload"
                alt="Acko"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/incubyte.png"
                className="lazyload"
                alt="incubyte"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/drivex.png"
                className="lazyload"
                alt="drivex"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/bira.png"
                className="lazyload"
                alt="bira"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/apna.png"
                className="lazyload"
                alt="apna"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/gsk.png"
                className="lazyload"
                alt="gsk"
              ></img>
            </div>
            <div width="200px">
              <img
                data-src="https://assets.gofloaters.com/clients/newset/notionpress.png"
                className="lazyload"
                alt="Notion Press"
              ></img>
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}
export default ClientLogos
